<template>
	<v-app>
		<v-main>
			<v-container no-gutters fluid class="pa-0">
				<v-sheet :height="$vuetify.breakpoint.mdAndUp ? 600 : 500">
					<v-img :src="imgFAC" :gradient="'to top, rgba(80,80,80,.6), rgba(20,20,20,.8)'" height="100%">
						<v-app-bar flat color="transparent">
							<v-avatar tile size="48" class="mr-3"><img :src="etctaoLogo"></v-avatar>
							<v-spacer></v-spacer>
							<v-btn outlined color="white" @click="goToLogin()">Entrar</v-btn>
						</v-app-bar>
						<!-- DESKTOP VIEW -->
						<v-row row wrap v-if="$vuetify.breakpoint.mdAndUp">
							<v-col sm="12" md="6" cols="12">
								<div style="padding-left: 130px; padding-top: 180px;">
									<p class="mb-0 text-h2 white--text font-weight-medium">FAC</p>
									<p class="mb-0 text-h5 white--text font-weight-light">Facilitador do Autoconhecimento</p>

									<v-btn color="success" @click="goToLogin()" class="mt-4">Faça Agora Gratuitamente</v-btn><br />
									<v-btn href="#FAC_ABOUT" outlined color="white" class="mt-4">Saiba Mais <v-icon right>mdi-arrow-down</v-icon></v-btn>
								</div>
							</v-col>
						</v-row>

						<!-- MOBILE VIEW -->
						<v-row row wrap v-else>
							<v-col sm="12" md="6" cols="12">
								<div style="padding-left: 30px; padding-top: 80px;">
									<p class="mb-0 text-h2 white--text font-weight-medium">FAC</p>
									<p class="mb-0 text-h5 white--text font-weight-light">Facilitador do Autoconhecimento</p>

									<v-btn color="success" @click="goToLogin()" class="mt-4">Faça Agora Gratuitamente</v-btn><br />
									<v-btn outlined color="white" class="mt-4" @click="$vuetify.goTo('#FAC_ABOUT')">Saiba Mais <v-icon right>mdi-arrow-down</v-icon></v-btn>
								</div>
							</v-col>
						</v-row>
					</v-img>
				</v-sheet>

				<v-sheet class="grey lighten-3 pa-4">
					<p class="mb-0 text-h4 pa-4" id="FAC_ABOUT">Sobre</p>
					<v-row row wrap align="stretch">
						<v-col sm="12" md="3" cols="12"></v-col>
						<v-col sm="12" md="3" cols="12">
							<v-card max-width="344" height="auto">
								<v-img :src="imgFAC" height="200px"></v-img>
								<v-card-title>O FAC</v-card-title>
								<v-card-text style="height: 236px;">
									O aplicativo FAC (Facilitador do Autoconhecimento) tem como objetivo, por meio de seu teste, te apresentar uma análise de seus valores pessoais de forma descritiva e gráfica de como suas escolhas influenciam no seu comportamento ou desempenho pessoal.
								</v-card-text>
							</v-card>
						</v-col>
						<v-col sm="12" md="3" cols="12">
							<v-card max-width="344" height="auto">
								<v-img :src="imgFAC3" height="200px"></v-img>
								<v-card-title>O Teste</v-card-title>
								<v-card-text style="height: 236px;">
									Serão mostradas 80 cartas, que deverão ser selecionadas de 4 em 4 cartas e ordenadas de acordo com suas percepções de prioridades. Ao término das cartas será gerado um relatório de acordo com o tipo de teste selecionado. Você terá acesso do relatório pelo prazo de 1 (um) ano e poderá acessá-lo de qualquer dispositivo com acesso a internet.
								</v-card-text>
							</v-card>
						</v-col>
						<v-col sm="12" md="3" cols="12"></v-col>
					</v-row>
				</v-sheet>

				<v-sheet class="grey lighten-3">
					<v-img :src="imgFAC2" :gradient="'to top, rgba(80,80,80,.6), rgba(20,20,20,.8)'" height="100%">
						<p class="mb-0 text-h3 pa-4 text-center white--text">Preços</p>
						<fac-products :showFree="true" :fpAlign="'center'" :fpJustify="'center'"></fac-products>
					</v-img>
				</v-sheet>

				<v-footer :padless="true">
      				<v-card flat tile width="100%" class="text-center">
						<v-card-text>
							<strong>FAC</strong> — <a href="https://www.etctao.com.br/" target="_blank">ETC&TAO</a>© {{ new Date().getFullYear() }} — Criado com <v-icon color="error" small>mdi-heart-outline</v-icon> por NikoWorks
						</v-card-text>
					</v-card>
    			</v-footer>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import facLogo from '../assets/img/etctao.png'
import bgImage from '../assets/img/fac-img2.jpg'
import bgImage2 from '../assets/img/fac-img3.jpg'
import cardBG from '../assets/img/card-bg.jpg'
import FacProducts from '../components/items/Product.vue'

export default {
	name: 'Landing',
	components: {
		'fac-products': FacProducts
	},
	data() {
		return {
			imgFAC: bgImage,
			imgFAC2: bgImage2,
			imgFAC3: cardBG,
			etctaoLogo: facLogo,
			buttonOptions: null,
			buttonOptions2: null,
			buttonOptions3: null,
			products: []
		}
	},
	methods: {
		goToLogin() {
			this.$router.push('/entrar')
		}
	}
}
</script>